import { PDFDocument, PDFPage, StandardFonts, degrees, rgb } from "pdf-lib";
import * as pdfjs from "pdfjs-dist";
import { multipleImageOCR, printFile, textToBase64Barcode } from "./globals";

export const createNewPDF = async () => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage();

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

const drawWatermark = (page: PDFPage, text: string) => {
  const { width, height } = page.getSize();
  for (let i = 0; i < height; i += height / 10) {
    for (let j = 10; j < width; j += width / 10) {
      page.drawText(text, {
        x: j,
        y: i,
        size: 10,
        rotate: degrees(-45),
        opacity: 0.25,
      });
    }
  }
};

export const addWatermarkToPDF = async (file: any, text: string) => {
  const pdfDoc = await PDFDocument.load(file);
  const pages = pdfDoc.getPages();
  pages.forEach((value) => drawWatermark(value, text));
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addImageToPDF = async (
  file: any,
  img: any,
  imgType: any,
  dim: any
) => {
  const pdfDoc = await PDFDocument.load(file);
  const pages = pdfDoc.getPages();
  const image =
    imgType === "png" ? await pdfDoc.embedPng(img) : await pdfDoc.embedJpg(img);
  const dims = image.scale(dim.scale);
  const page = pages[0];
  page.drawImage(image, {
    x: dim.x,
    y: dim.y,
    width: dims.width,
    height: dims.height,
  });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addSignatureToPDF = async (
  file: any,
  img: any,
  imgType: any,
  timeStamp: string,
  dim: any
) => {
  const pdfDoc = await PDFDocument.load(file);
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const pages = pdfDoc.getPages();
  const image =
    imgType === "png" ? await pdfDoc.embedPng(img) : await pdfDoc.embedJpg(img);
  const dims = image.scale(dim.scale);
  const page = pages[dim.page - 1];
  const { width, height } = page.getSize();
  const rotation = page.getRotation();

  console.log(pages, page);

  let xPos = dim.x;
  let yPos = height - dim.y;

  if (rotation.angle === 270) {
    xPos = width - dim.y;

    // max selection area is WxH(P) but drawable area is WxH(L)
    yPos = height - dim.x > 0 ? height - dim.x : 100;
  }

  console.log({ dimX: dim.x, dimY: dim.y, xPos, yPos, width, height });

  page.drawImage(image, {
    x: xPos,
    y: yPos,
    width: dims.width,
    height: dims.height,
    rotate: rotation,
  });
  page.drawText(timeStamp, {
    x: xPos - 8,
    y: yPos,
    size: 8,
    font: timesRomanFont,
    color: rgb(0, 0, 1),
    rotate: rotation,
  });
  // page.drawText("(100,10)", {
  //   x: 100,
  //   y: 10,
  //   size: 15,
  //   font: timesRomanFont,
  //   color: rgb(1, 0, 0), //rgb(0, 0, 1),
  // });
  // page.drawText("(100,10)R", {
  //   x: 100,
  //   y: 10,
  //   size: 15,
  //   font: timesRomanFont,
  //   color: rgb(1, 0, 0), //rgb(0, 0, 1),
  //   rotate: rotation,
  // });

  // page.drawText("(10,570)", {
  //   x: 10,
  //   y: 570,
  //   size: 15,
  //   font: timesRomanFont,
  //   color: rgb(1, 0, 0), //rgb(0, 0, 1),
  // });
  // page.drawText("(10,570)R", {
  //   x: 10,
  //   y: 570,
  //   size: 15,
  //   font: timesRomanFont,
  //   color: rgb(1, 0, 0), //rgb(0, 0, 1),
  //   rotate: rotation,
  // });

  // page.drawText("(800,570)", {
  //   x: 800,
  //   y: 570,
  //   size: 15,
  //   font: timesRomanFont,
  //   color: rgb(1, 0, 0), //rgb(0, 0, 1),
  // });
  // page.drawText("(800,10)", {
  //   x: 800,
  //   y: 10,
  //   size: 15,
  //   font: timesRomanFont,
  //   color: rgb(1, 0, 0), //rgb(0, 0, 1),
  // });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addTextToPDF = async (file: any, text: string, dim: any) => {
  const pdfDoc = await PDFDocument.load(file);
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const pages = pdfDoc.getPages();
  const page = pages[0];
  page.drawText(text, {
    x: dim.x,
    y: dim.y,
    size: 10,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
  });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const mergePDFs = async (pdf1: any, pdf2: any) => {
  const pdfDoc1 = await PDFDocument.load(pdf1);
  const pdfDoc2 = await PDFDocument.load(pdf2);

  const copiedPages = await pdfDoc1.copyPages(
    pdfDoc2,
    pdfDoc2.getPageIndices()
  );
  copiedPages.forEach((page) => {
    pdfDoc1.addPage(page);
  });
  const pdfBytes = await pdfDoc1.save();
  return pdfBytes;
};

export const convertPdfToImages = async (file: any) => {
  const buffer = await file.arrayBuffer().then((res: any) => res);
  pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs-worker/pdf.worker.mjs`;
  let images: any = [];
  const pdf = await pdfjs.getDocument(buffer).promise;

  const canvas = document.createElement("canvas");
  const grayCanvas = document.createElement("canvas");

  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1 });
    console.log({ viewport });

    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvas.style.width = "100%";
    //    context.filter = "grayscale(1)";
    await page.render({
      canvasContext: context,
      viewport: viewport,
    }).promise;

    //const grayContext = grayCanvas.getContext("2d") as CanvasRenderingContext2D;
    grayCanvas.height = viewport.height;
    grayCanvas.width = viewport.width;
    grayCanvas.style.width = "100%";

    convertToGrayscale(canvas, grayCanvas);
    //images.push(canvas.toDataURL());
    images.push(grayCanvas.toDataURL());
  }
  canvas.remove();
  grayCanvas.remove();

  return images;
};

function convertToGrayscale(srcCanvas: any, destCanvas: any) {
  const srcCtx = srcCanvas.getContext("2d");
  const destCtx = destCanvas.getContext("2d");

  const width = srcCanvas.width;
  const height = srcCanvas.height;

  // Get the image data from the source canvas
  const imageData = srcCtx.getImageData(0, 0, width, height);
  const data = imageData.data;

  // Convert each pixel to grayscale
  for (let i = 0; i < data.length; i += 4) {
    const red = data[i];
    const green = data[i + 1];
    const blue = data[i + 2];

    // Calculate the grayscale value
    const gray = 0.3 * red + 0.59 * green + 0.11 * blue;

    // Set the red, green, and blue channels to the grayscale value
    data[i] = gray;
    data[i + 1] = gray;
    data[i + 2] = gray;
  }

  // Put the modified image data onto the destination canvas
  destCtx.putImageData(imageData, 0, 0);
}
export const getContentFromPdf = async (file: any) => {
  return "";

  const doOCR = process.env.REACT_DISABLE_OCR
    ? Boolean(process.env.REACT_ENABLE_OCR)
    : true;

  if (!doOCR) return "";

  const imagesFromPDF = await convertPdfToImages(file);

  console.log({ imagesFromPDF });

  var startTime = new Date().getTime();
  const content = await multipleImageOCR(imagesFromPDF);
  var endTime = new Date().getTime();

  console.log(`OCR Processing time: ${(endTime - startTime) / 1000}secs`);

  return content;
};

export const PrintDocumentWithBarcode = async (
  data: any,
  barcodeText: string
) => {
  let updatedPDF = await createNewPDF();

  for (let index = 0; index < data.length; index++) {
    const lineData = data[index];

    updatedPDF = await addTextToPDF(
      updatedPDF,
      `${lineData.label}: ${lineData.value}`,
      {
        x: lineData.position.x,
        y: lineData.position.y,
      }
    );
  }

  const barcode = textToBase64Barcode(barcodeText);
  const finalPDF = await addImageToPDF(updatedPDF, barcode, "png", {
    x: 100,
    y: 640,
    scale: 0.5,
  });
  const blob = new Blob([finalPDF], { type: "application/pdf" });
  const link = window.URL.createObjectURL(blob);

  printFile(link);
};
