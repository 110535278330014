import {
	List,
	ListItem,
	Checkbox,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Divider,
	Chip,
} from "@mui/material";
import IconMenu from "../../controls/iconmenu";
import { useTranslation } from "react-i18next";
import EmptyView from "./emptyview";
import { useEffect, useState } from "react";
import FileViewerModal from "../../editmodals/DMS/fileviewermodal";
import DeleteModal from "../../controls/deleteModal";
import CopyModal from "../../editmodals/DMS/copymodal";
import DirectoryModal from "../../editmodals/DMS/directorymodal";
import DocumentUploadModal from "../../editmodals/DMS/documentuploadmodal";
import MoveModal from "../../editmodals/DMS/movemodal";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNotificationContext } from "../../../context/notificationcontext";
import useFolderService from "../../../shared/services/folderservice";
import useDocumentService from "../../../shared/services/documentservice";
import RestoreModal from "../../controls/restoremodal";
import {
	textToBase64Barcode,
	printFile,
	DateTimeFormatter,
	DateFormatter,
} from "../../../shared/globals";
import {
	createNewPDF,
	addTextToPDF,
	addImageToPDF,
} from "../../../shared/pdfoperations";
import {
	Article,
	Folder,
	HourglassBottomOutlined,
	Image,
} from "@mui/icons-material";
import GridDX from "../../layout/griddx";
import { useAuthContext } from "../../../context/authcontext";
import moment from "moment";
import SideBar from "./sidebar";
import { ListChildComponentProps } from "react-window";
import VirtualizedListDX from "../../layout/virtuallistdx";

const ContentView = (props: any) => {
	const {
		folders,
		documents,
		checkedFolders,
		checkedFiles,
		cabinetId,
		folderId,
		toSelect,
		toRefresh,
		isTrash,
		onFolderChange,
		forSearch,
		showFolderPath,
	} = props;
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { userData } = useAuthContext();
	const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
	const { setError, setInfo } = useNotificationContext();
	const { moveFolder, copyFolder, archiveFolder, restoreFolder, deleteFolder } =
		useFolderService();
	const {
		moveDocument,
		copyDocument,
		archiveDocument,
		restoreDocument,
		deleteDocument,
	} = useDocumentService();

	const [file, setFile] = useState<any>(null);
	const [selectedFolder, setSelectedFolder] = useState<any>(null);
	const [editDir, setEditDir] = useState<any>(null);
	const [editFile, setEditFile] = useState<any>(null);
	const [deleteModal, setDeleteModal] = useState<any>({
		open: false,
		id: null,
		document: false,
	});
	const [restoreModal, setRestoreModal] = useState<any>({
		open: false,
		id: null,
		document: false,
	});
	const [openDirectoryModal, setOpenDirectoryModal] = useState(false);
	const [openFileViewer, setOpenFileViewer] = useState(false);
	const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
	const [openMoveModal, setOpenMoveModal] = useState({
		open: false,
		id: null,
		document: false,
	});
	const [openCopyModal, setOpenCopyModal] = useState({
		open: false,
		id: null,
		document: false,
	});
	const [selectedItem, setSelectedItem] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(false);
	let clickTimeout: string | number | NodeJS.Timeout | undefined;

	useEffect(() => {
		setSelectedItem(null);
	}, [folderId]);

	const toPrintBarcode = async (
		filename: string,
		barcodeText: string,
		location: string = ""
	) => {
		const timeStamp = DateTimeFormatter(
			moment(),
			calendarId,
			gregorianDateFormatId,
			hijriDateFormatId
		);

		const newPDF = await createNewPDF();
		let updatedPDF = await addTextToPDF(newPDF, `Name: ${filename}`, {
			x: 10,
			y: 800,
		});
		if (location !== "")
			updatedPDF = await addTextToPDF(
				updatedPDF,
				`Physical Location: ${location}`,
				{ x: 10, y: 780 }
			);
		updatedPDF = await addTextToPDF(updatedPDF, `Date: ${timeStamp}`, {
			x: 10,
			y: 760,
		});
		const barcode = textToBase64Barcode(barcodeText);
		const finalPDF = await addImageToPDF(updatedPDF, barcode, "png", {
			x: 100,
			y: 640,
			scale: 0.5,
		});
		const blob = new Blob([finalPDF], { type: "application/pdf" });
		const link = window.URL.createObjectURL(blob);

		printFile(link);
	};

	const onClose = (refreshPage: boolean = false) => {
		setDeleteModal({ open: false, id: null, document: false });
		setRestoreModal({ open: false, id: null, document: false });
		setOpenDirectoryModal(false);
		setOpenMoveModal({ open: false, id: null, document: false });
		setOpenCopyModal({ open: false, id: null, document: false });
		setOpenFileUploadModal(false);

		if (refreshPage) toRefresh();
	};

	const toMove = () => {
		if (openMoveModal.document) {
			if (!selectedFolder.folderId) {
				setError("Document cannot be moved, please select a folder");
				return;
			}
			const data = {
				documentId: openMoveModal.id,
				newFolderId: selectedFolder.folderId,
			};
			moveDocument(data)
				.then((res: any) => {
					setInfo(t("Document moved successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err));
		} else {
			onFolderChange(null);
			const data = {
				folderId: openMoveModal.id,
				newParentFolderId: selectedFolder?.folderId || -1,
				newCabinetId:
					cabinetId === selectedFolder?.cabinetId
						? -1
						: selectedFolder?.cabinetId,
			};
			moveFolder(data)
				.then((res: any) => {
					setInfo(t("Directory moved successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err));
		}
	};

	const toCopy = () => {
		if (openCopyModal.document) {
			if (!selectedFolder.folderId) {
				setError("Document cannot be copied, please select a folder");
				return;
			}
			const data = {
				documentId: openCopyModal.id,
				newFolderId: selectedFolder.folderId,
			};
			copyDocument(data)
				.then((res: any) => {
					setInfo(t("Document copied successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err));
		} else {
			onFolderChange(null);
			const data = {
				folderId: openCopyModal.id,
				newParentFolderId: selectedFolder?.folderId || -1,
				newCabinetId:
					cabinetId === selectedFolder?.cabinetId
						? -1
						: selectedFolder?.cabinetId,
			};
			copyFolder(data)
				.then((res: any) => {
					setInfo(t("Directory copied successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err));
		}
	};

	const toDelete = () => {
		setIsLoading(true);
		if (deleteModal.document) {
			archiveDocument(deleteModal.id)
				.then((content: any) => {
					setInfo(t("Document deleted successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err))
				.finally(() => setIsLoading(false));
		} else {
			archiveFolder(deleteModal.id)
				.then((content: any) => {
					setInfo(t("Directory deleted successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err))
				.finally(() => setIsLoading(false));
		}
	};

	const toDeleteForever = () => {
		setIsLoading(true);
		if (deleteModal.document) {
			deleteDocument(deleteModal.id)
				.then((content: any) => {
					setInfo(t("Document deleted successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err))
				.finally(() => setIsLoading(false));
		} else {
			deleteFolder(deleteModal.id)
				.then((content: any) => {
					setInfo(t("Directory deleted successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err))
				.finally(() => setIsLoading(false));
		}
	};

	const toRestore = () => {
		setIsLoading(true);
		if (restoreModal.document) {
			restoreDocument(restoreModal.id)
				.then((content: any) => {
					setInfo(t("Document restored successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err));
		} else {
			restoreFolder(restoreModal.id)
				.then((content: any) => {
					setInfo(t("Directory restored successfully"));
					onClose(true);
				})
				.catch((err: any) => setError(err))
				.finally(() => setIsLoading(false));
		}
	};

	const toDownloadDocument = (doc: any) => {
		fetch(doc.downloadURL, {
			headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
		})
			.then(async (res) => {
				const blob = await res.blob();
				const file = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = file;
				link.setAttribute("download", doc.enName);
				document.body.appendChild(link);
				link.click();
			})
			.catch((err: any) => setError(err));
	};

	const toViewDocument = (document: any) => {
		if ([".xlsx", ".pptx", ".docx"].includes(document.fileExtension))
			toDownloadDocument(document);
		else {
			setFile(document);
			setOpenFileViewer(true);
		}
	};

	const getDocumentIcon = (fileExtension: string) => {
		if (fileExtension === ".pdf") return <PictureAsPdfIcon />;
		else if ([".jpg", ".jpeg", ".png"].includes(fileExtension))
			return <Image />;
		else return <Article />;
	};

	const renderFolderListItems = (props: ListChildComponentProps) => {
		const { index, style } = props;

		return (
			<div key={index} style={style}>
				<ListItem
					selected={
						checkedFolders.indexOf(folders[index].folderId) !== -1 ||
						selectedItem === folders[index]
					}
				>
					{!isTrash && (
						<Checkbox
							edge="start"
							checked={checkedFolders.indexOf(folders[index].folderId) !== -1}
							onClick={() => toSelect(folders[index].folderId, "folder")}
						/>
					)}
					<ListItemButton
						onClick={() => {
							clearTimeout(clickTimeout);
							clickTimeout = setTimeout(() => {
								if (!isTrash)
									setSelectedItem({
										id: folders[index].folderId,
										type: "folder",
									});
							}, 200);
						}}
						onDoubleClick={() => {
							clearTimeout(clickTimeout);
							if (!isTrash) onFolderChange(folders[index]);
						}}
					>
						<ListItemIcon>
							<Folder />
						</ListItemIcon>
						<ListItemText
							primary={
								i18n.language === "en"
									? folders[index].enName
									: folders[index].arName
							}
							primaryTypographyProps={{ textAlign: "start" }}
						/>
					</ListItemButton>
					<IconMenu edge="end" icon={<MenuIcon />} sx={{ mx: 1 }}>
						{!isTrash ? (
							<div>
								<MenuItem
									onClick={() =>
										navigate(`/dms/directory/${folders[index].folderId}`)
									}
								>
									{t("Information")}
								</MenuItem>
								<MenuItem
									onClick={() => {
										setEditDir(folders[index]);
										setOpenDirectoryModal(true);
									}}
								>
									{t("Edit")}
								</MenuItem>
								<MenuItem
									onClick={() =>
										toPrintBarcode(
											folders[index].enName,
											`folderId: ${folders[index].folderId}`
										)
									}
								>
									{t("Print Barcode")}
								</MenuItem>
								<MenuItem
									onClick={() =>
										setOpenCopyModal({
											open: true,
											id: folders[index].folderId,
											document: false,
										})
									}
								>
									{t("Copy")}
								</MenuItem>
								<MenuItem
									onClick={() =>
										setOpenMoveModal({
											open: true,
											id: folders[index].folderId,
											document: false,
										})
									}
								>
									{t("Move")}
								</MenuItem>
								<MenuItem
									onClick={() =>
										setDeleteModal({
											open: true,
											id: folders[index].folderId,
											document: false,
										})
									}
								>
									{t("Delete")}
								</MenuItem>
							</div>
						) : (
							<div>
								<MenuItem
									onClick={() =>
										setRestoreModal({
											open: true,
											id: folders[index].folderId,
											document: false,
										})
									}
								>
									{t("Restore")}
								</MenuItem>
								{userData.isAdmin && (
									<MenuItem
										onClick={() =>
											setDeleteModal({
												open: true,
												id: folders[index].folderId,
												document: false,
											})
										}
									>
										{t("Delete Forever")}
									</MenuItem>
								)}
							</div>
						)}
					</IconMenu>
				</ListItem>
				<Divider />
			</div>
		);
	};

	const renderDocumentListItems = (props: ListChildComponentProps) => {
		const { index, style } = props;
		const dIndex = index - folders.length;

		return (
			<div key={index} style={style}>
				<ListItem
					selected={
						checkedFiles.indexOf(documents[dIndex].documentId) !== -1 ||
						selectedItem === documents[dIndex]
					}
				>
					{!isTrash && !forSearch && (
						<Checkbox
							edge="start"
							checked={
								checkedFiles.indexOf(documents[dIndex].documentId) !== -1
							}
							onClick={() => toSelect(documents[dIndex].documentId, "file")}
						/>
					)}
					<ListItemButton
						onClick={() => {
							clearTimeout(clickTimeout);
							clickTimeout = setTimeout(() => {
								if (!isTrash && !forSearch)
									setSelectedItem({
										id: documents[dIndex].documentId,
										type: "document",
									});
							}, 200);
						}}
						onDoubleClick={() => {
							clearTimeout(clickTimeout);
							if (!isTrash) toViewDocument(documents[dIndex]);
						}}
					>
						<ListItemIcon>
							{getDocumentIcon(documents[dIndex].fileExtension)}
						</ListItemIcon>
						<ListItemText
							sx={{ display: "flex", alignItems: "center" }}
							primary={
								<div style={{ display: "flex", alignItems: "center" }}>
									{showFolderPath &&
										(i18n.language === "en"
											? documents[dIndex].enFolderPath + "/"
											: documents[dIndex].arFolderPath + "/")}
									{i18n.language === "en"
										? documents[dIndex].enName
										: documents[dIndex].arName}
									{documents[dIndex]?.documentCategories?.length > 0 &&
										documents[dIndex]?.documentCategories?.map(
											(category: any, index: number) => {
												return (
													<Chip
														key={index}
														sx={{
															ml: 1,
															backgroundColor: category.categoryLabelColor,
														}}
														label={
															i18n.language === "en"
																? category?.categoryEnName || ""
																: category?.categoryArName || ""
														}
													/>
												);
											}
										)}
									{documents[dIndex].expirationDate && (
										<Chip
											icon={<HourglassBottomOutlined />}
											label={DateFormatter(
												documents[dIndex].expirationDate,
												calendarId,
												gregorianDateFormatId,
												hijriDateFormatId
											)}
											sx={{ my: 0, mx: 1, px: 1 }}
											color="warning"
										/>
									)}
								</div>
							}
							primaryTypographyProps={{ textAlign: "start" }}
						/>
					</ListItemButton>
					<IconMenu edge="end" icon={<MenuIcon />} sx={{ mx: 1 }}>
						{!isTrash ? (
							<div>
								<MenuItem
									onClick={() =>
										navigate(`/dms/documents/${documents[dIndex].documentId}`)
									}
								>
									{t("Information")}
								</MenuItem>
								<MenuItem
									onClick={() => {
										setEditFile(documents[dIndex]);
										setOpenFileUploadModal(true);
									}}
								>
									{t("Edit")}
								</MenuItem>
								<MenuItem onClick={() => toViewDocument(documents[dIndex])}>
									{t("Preview")}
								</MenuItem>
								<MenuItem onClick={() => toDownloadDocument(documents[dIndex])}>
									{t("Download")}
								</MenuItem>
								<MenuItem
									onClick={() =>
										toPrintBarcode(
											documents[dIndex].enName,
											`documentId: ${documents[dIndex].documentId}`,
											documents[dIndex].dirLocation
										)
									}
								>
									{t("Print Barcode")}
								</MenuItem>
								<MenuItem
									onClick={() =>
										setOpenCopyModal({
											open: true,
											id: documents[dIndex].documentId,
											document: true,
										})
									}
								>
									{t("Copy")}
								</MenuItem>
								<MenuItem
									onClick={() =>
										setOpenMoveModal({
											open: true,
											id: documents[dIndex].documentId,
											document: true,
										})
									}
								>
									{t("Move")}
								</MenuItem>
								<MenuItem
									onClick={() =>
										setDeleteModal({
											open: true,
											id: documents[dIndex].documentId,
											document: true,
										})
									}
								>
									{t("Delete")}
								</MenuItem>
							</div>
						) : (
							<div>
								<MenuItem
									onClick={() =>
										setRestoreModal({
											open: true,
											id: documents[dIndex].folderId,
											document: false,
										})
									}
								>
									{t("Restore")}
								</MenuItem>
								{userData.isAdmin && (
									<MenuItem
										onClick={() =>
											setDeleteModal({
												open: true,
												id: documents[dIndex].documentId,
												document: true,
											})
										}
									>
										{t("Delete Forever")}
									</MenuItem>
								)}
							</div>
						)}
					</IconMenu>
				</ListItem>
				<Divider />
			</div>
		);
	};

	const Row = (props: ListChildComponentProps) => {
		if (props.index < folders.length) {
			// Render row from array1
			return renderFolderListItems(props);
		} else {
			// Render row from array2 with offset
			return renderDocumentListItems(props);
		}
	};

	return (
		<>
			{openFileViewer && (
				<FileViewerModal
					open={openFileViewer}
					handleClose={() => setOpenFileViewer(false)}
					document={file}
				/>
			)}
			{openDirectoryModal && (
				<DirectoryModal
					open={openDirectoryModal}
					handleClose={onClose}
					cabinetId={cabinetId}
					folderId={folderId}
					directory={editDir}
				/>
			)}
			{openFileUploadModal && (
				<DocumentUploadModal
					open={openFileUploadModal}
					handleClose={onClose}
					folderId={folderId}
					data={editFile}
				/>
			)}
			{openMoveModal.open && (
				<MoveModal
					open={openMoveModal.open}
					handleClose={onClose}
					folder={selectedFolder}
					onFolderSelect={setSelectedFolder}
					toMove={toMove}
				/>
			)}
			{openCopyModal.open && (
				<CopyModal
					open={openCopyModal.open}
					handleClose={onClose}
					folder={selectedFolder}
					onFolderSelect={setSelectedFolder}
					toCopy={toCopy}
				/>
			)}
			{deleteModal.open && (
				<DeleteModal
					open={deleteModal.open}
					deleteMessage={
						isTrash ? t("You want to delete this entry permanently") : ""
					}
					onDeleteClick={!isTrash ? toDelete : toDeleteForever}
					onCancelClick={() =>
						setDeleteModal({ open: false, id: null, document: false })
					}
					isLoading={isLoading}
				/>
			)}
			{restoreModal.open && (
				<RestoreModal
					open={restoreModal.open}
					onRestoreClick={toRestore}
					onCancelClick={() =>
						setRestoreModal({ open: false, id: null, document: false })
					}
				/>
			)}
			{folders.length + documents.length > 0 ? (
				<GridDX height={"100%"} width={"100%"} sx={{ overflow: "hidden" }}>
					<GridDX
						item
						xs={selectedItem ? 9 : 12}
						height={"100%"}
						sx={{ overflow: "hidden" }}
					>
						<VirtualizedListDX
							itemCount={folders.length + documents.length}
							renderRow={Row}
						/>
					</GridDX>
					{selectedItem && (
						<GridDX item xs={3} height={"100%"}>
							<SideBar
								item={selectedItem}
								toClose={() => setSelectedItem(null)}
							/>
						</GridDX>
					)}
				</GridDX>
			) : (
				<EmptyView showInstruction={!forSearch && !isTrash} />
			)}
		</>
	);
};

export default ContentView;
