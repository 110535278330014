import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PageTitle = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const routeMap = new Map<string, string>([
    ["users", t("Users")],
    ["userroles", t("User Roles")],
    ["usergroup", t("User Groups")],
    ["userprofile", t("Profile")],
    ["cts/dashboard", t("Dashboard")],
    ["cts/correspondence", t("Correspondence")],
    ["cts/reports", t("Reports")],
    ["cts/entitytypes", t("Entity Types")],
    ["cts/privacylevels", t("Privacy Levels")],
    ["cts/importancelevels", t("Importance Levels")],
    ["cts/statustypes", t("Status Types")],
    ["cts/entities", t("Entities")],
    ["cts/domaintypes", t("Domain Types")],
    ["cts/deliverytypes", t("Delivery Types")],
    ["cts/contacttypes", t("Contact Types")],
    ["cts/referencetypes", t("Reference Types")],
    ["cts/correspondencetypes", t("Correspondence Types")],
    ["cts/delegations", t("My Delegations")],
    ["cts/routinggroup", t("Routing Group")],
    ["cts/templates", t("Document Templates")],
    ["cts/statustypes", t("Status Types")],
    ["cts/category", t("Category")],
    ["cts/logs", t("Logs")],
    ["dms/documents", t("Document Explorer")],
    ["dms/cabinets", t("Cabinets")],
    ["dms/structuretemplates", t("Structure Templates")],
    ["dms/documentprofiles", t("Document Profiles")],
    ["dms/documenttypes", t("Document Types")],
    ["dms/documentcategories", t("Document Categories")],
    ["dms/contenteditingrequests", t("Content Editing Requests")],
    ["dms/documenteditor", t("Document Editor")],
    ["dms/attachmentsearch", t("Attachment Search")],
    ["dms/reports", t("DMS Reports")],
    ["dms/logs", t("Logs")],
    ["vms/dashboard", t("Dashboard")],
    ["vms/appointments", t("Appointments")],
    ["vms/visitors", t("Visitors")],
    ["vms/groups", t("Groups")],
    ["vms/reports", t("Reports")],
    ["vms/workflows", t("Workflows")],
  ]);

  const [pageHeading, setPageHeading] = useState("Dashboard");

  useEffect(() => {
    let pathName = location.pathname.substring(1);
    // const idPath = pathName.indexOf("/");
    // if (idPath != -1) {
    //   pathName = pathName.substring(0, idPath);
    // }
    updatePageHeading(routeMap.get(pathName));
  }, [location.pathname, i18n.language]);

  const updatePageHeading = async (newPageHeading: string | undefined) => {
    setPageHeading(newPageHeading ?? "Page Title");
  };

  return (
    <Typography
      variant="h4"
      component="div"
      sx={{ flexGrow: 1, fontWeight: "bold", py: 2 }}
    >
      {pageHeading}
    </Typography>
  );
};

export default PageTitle;
