import { Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import FileViewer from "../../business/fileviewer";
import { useNotificationContext } from "../../../context/notificationcontext";
import { getContentFromPdf } from "../../../shared/pdfoperations";
import useDocumentVersionService from "../../../shared/services/documentversionservice";
import useDocumentService from "../../../shared/services/documentservice";
import { useEffect, useState } from "react";
import Loading from "../../loading";
import { useAuthContext } from "../../../context/authcontext";
import { DateTimeFormatter } from "../../../shared/globals";

const FileViewerModal = (props: any) => {
  const { i18n, t } = useTranslation();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { setError, setInfo } = useNotificationContext();
  const { getDocumentByID } = useDocumentService();
  const { addDocumentVersion } = useDocumentVersionService();
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState<any>(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    getDocumentByID(props.document.documentId)
      .then((res) => {
        setDocument(res);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toUpdate = async (file: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("DocumentId", document.documentId);
    formData.append("File", file);

    getContentFromPdf(file)
      .then((content) => {
        formData.append("ContentAsText", content);
        saveDocument(formData);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const saveDocument = (document: any) => {
    addDocumentVersion(document)
      .then(() => {
        setInfo(t("Document updated successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const showMoreInfo = () => {
    const path = location.pathname.split("/");
    if (parseInt(path[path.length - 1]) === document.documentId)
      props.handleClose();
    else navigate(`/dms/documents/${document.documentId}`);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      fullScreen
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogContent>
        {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
        {document && (
          <FileViewer
            fileName={
              i18n.language === "en" ? document.enName : document.arName
            }
            fileURL={document.downloadURL}
            fileType={
              document.fileExtension ? document.fileExtension.split(".")[1] : ""
            }
            fileContentType={document.contentType}
            toUpdate={toUpdate}
            sideInfo={{
              Owner:
                i18n.language === "en"
                  ? document.createdByUser.enFullName
                  : document.createdByUser.arFullName,
              "Creation Date": DateTimeFormatter(
                document.createdDate,
                calendarId,
                gregorianDateFormatId,
                hijriDateFormatId
              ),
              Categories: document.documentCategories
                .map((item: any) =>
                  i18n.language === "en"
                    ? item.categoryEnName
                    : item.categoryArName
                )
                .join(),
              "Active Version": document.versionNumber,
            }}
            forMoreInfo={showMoreInfo}
            onCloseClick={props.handleClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FileViewerModal;
