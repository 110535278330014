import { NormalizedSelection, PdfViewer } from "react-pdf-selection";
import AddEditModalDX from "../business/addeditmodaldx";
import Loading from "../loading";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../context/notificationcontext";
import { useEffect, useState, useRef } from "react";
import { convertToTwoDigits } from "../../shared/globals";
import { addSignatureToPDF } from "../../shared/pdfoperations";
import useCorrespondenceActionService from "../../shared/services/correspondenceactionservice";
import useUserService from "../../shared/services/userservices";
import { useAuthContext } from "../../context/authcontext";
import { Typography } from "@mui/material";
import OTPModal from "../business/otpmodal";
import useMainAttachmentService from "../../shared/services/mainattachmentservice";
import GridDX from "../layout/griddx";
import CheckBoxDX from "../controls/checkboxdx";
import BoxDX from "../layout/boxdx";

const SignatureModal = (props: any) => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { updateMainAttachment } = useMainAttachmentService();
  const { getUserById } = useUserService();
  const { sendOTP } = useCorrespondenceActionService();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [pdf, setPdf] = useState<any>(null);
  const [label, setLabel] = useState(t("Next"));
  const [page, setPage] = useState(1);
  const [state, setState] = useState<any>({
    pdf: null,
    signature: "",
    stamp: "",
  });
  const [choice, setChoice] = useState({
    sig: false,
    stamp: false,
  });
  const [signPos, setSignPos] = useState({ x: 10, y: 10, page: 1 });
  const [stampPos, setStampPos] = useState({ x: 10, y: 10, page: 1 });
  const positionRef = useRef({ x: 10, y: 10, page: 1 });
  const viewerRef = useRef<null | boolean>(null);
  const isOTPRequired = false;

  useEffect(() => {
    setIsLoading(true);
    const p1 = fetch(props.data.mainAttachmentURL, {
      headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
    });
    const p2 = getUserById(userData.userId);
    Promise.all([p1, p2])
      .then(([doc, sig]) => {
        if (!sig.signatureImage) {
          setError(t("No signature uploaded!"));
          setState({ ...state, signature: null });
        }
        if (!sig.stampImage) {
          setError(t("No stamp uploaded!"));
          setState({ ...state, stamp: null });
        }
        doc.arrayBuffer().then(async (attachment: any) => {
          const blob = new Blob([attachment], { type: "application/pdf" });
          const link = URL.createObjectURL(blob);
          setPdf(attachment);
          setState({
            pdf: link,
            signature: sig.signatureImage,
            stamp: sig.stampImage,
          });
        });
      })
      .catch((err) => {
        setError(err);
        handleClose();
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      });
  }, []);

  const showRect = (highlightTip?: NormalizedSelection) => {
    positionRef.current = {
      x: highlightTip?.position.absolute.boundingRect.left as number,
      y: highlightTip?.position.absolute.boundingRect.bottom as number,
      page: highlightTip?.position.pageNumber as number,
    };
    console.log(
      highlightTip
        ? `New ${"image" in highlightTip ? "area" : "text"} selection`
        : "Reset selection",
      highlightTip?.position
    );
  };

  const handleClose = () => {
    setOpenOTP(false);
    props.onClose();
  };

  const getOTP = async () => {
    const { x, y, page } = positionRef.current;
    if (x === 10 && y === 10) {
      setError(t("Please specify the location of the signature"));
      return;
    }
    sendOTP(props.data.correspondenceId)
      .then(() => {
        setOpenOTP(true);
      })
      .catch((err) => setError(err));
  };

  const addSignature = async (pdfFile: any, sigPos: any) => {
    const { x, y, page } = sigPos;
    if (x === 10 && y === 10) {
      setError(t("Please specify the location of the signature"));
      return;
    }
    viewerRef.current = true;
    var currentdate = new Date();
    var timeStamp =
      "Signed at: " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      convertToTwoDigits(currentdate.getHours()) +
      ":" +
      convertToTwoDigits(currentdate.getMinutes()) +
      ":" +
      convertToTwoDigits(currentdate.getSeconds());

    const type = state.signature.split(";")[0].split("/").pop();

    const updatedFile = await addSignatureToPDF(
      pdfFile,
      state.signature,
      type,
      timeStamp,
      { x, y, scale: 0.5, page }
    );

    return updatedFile;
  };

  const addStamp = async (pdfFile: any, staPos: any) => {
    const { x, y, page } = staPos;
    viewerRef.current = true;
    var currentdate = new Date();
    var timeStamp =
      "Signed at: " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      convertToTwoDigits(currentdate.getHours()) +
      ":" +
      convertToTwoDigits(currentdate.getMinutes()) +
      ":" +
      convertToTwoDigits(currentdate.getSeconds());

    const type = state.stamp.split(";")[0].split("/").pop();

    const updatedFile = await addSignatureToPDF(
      pdfFile,
      state.stamp,
      type,
      timeStamp,
      { x, y, scale: 0.5, page }
    );

    return updatedFile;
  };

  const onSave = async (sigPos: any, staPos: any) => {
    setIsSaving(true);
    let fileBytes = pdf;
    if (choice.sig) fileBytes = await addSignature(fileBytes, sigPos);
    if (choice.stamp) fileBytes = await addStamp(fileBytes, staPos);

    const blob = new Blob([fileBytes], { type: "application/pdf" });
    const file = new File([blob], "updatedDoc.pdf", {
      type: "application/pdf",
    });

    const formData = new FormData();
    formData.append("ExistingMainAttachmentId", props.data.mainAttachmentId);
    formData.append("CorrespondenceId", props.data.correspondenceId);
    formData.append("MainAttachmentFile", file);

    updateMainAttachment(formData)
      .then((result) => {
        props.toApprove();
        handleClose();
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const Viewer = ({ document }: any) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "100%" }}>{document}</div>
      </div>
    );
  };

  const getLabel = () => {
    if (page === 1) setLabel(t("Next"));
    else if (page === 2) {
      if (choice.sig && choice.stamp) setLabel(t("Next"));
      else setLabel(t("Save"));
    } else setLabel(t("Save"));
  };

  const onButtonClick = () => {
    if (!choice.sig && !choice.stamp) setError(t("Please select one!"));
    else if (choice.sig && choice.stamp) {
      if (page < 3) {
        if (page === 2) {
          if (positionRef.current.x === 10 && positionRef.current.y === 10) {
            setError(t("Please specify the location"));
            return;
          }
          setSignPos(positionRef.current);
        }
        setPage(page + 1);
      } else {
        if (positionRef.current.x === 10 && positionRef.current.y === 10) {
          setError(t("Please specify the location"));
          return;
        }
        onSave(signPos, positionRef.current);
      }
    } else {
      if (page < 2) setPage(page + 1);
      else {
        if (positionRef.current.x === 10 && positionRef.current.y === 10) {
          setError(t("Please specify the location"));
          return;
        }
        if (choice.sig) onSave(positionRef.current, null);
        else onSave(null, positionRef.current);
      }
    }
  };

  useEffect(() => {
    getLabel();
  }, [page]);

  return (
    <AddEditModalDX
      open={props.open}
      handleClose={handleClose}
      isSaving={isSaving}
      title={t("Approve Correspondence")}
      maxWidth="md"
      saveLabel={label}
      onSaveClick={onButtonClick}
      onYesClick={handleClose}
    >
      {openOTP && (
        <OTPModal
          open={openOTP}
          correspondenceId={props.data.correspondenceId}
          onClose={() => setOpenOTP(false)}
          toSave={addSignature}
        />
      )}
      {page === 1 &&
        (isLoading ? (
          <Loading styles={{ height: "100%", width: "100%" }} />
        ) : (
          <BoxDX
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            width="100%"
          >
            {state.signature && (
              <CheckBoxDX
                label={t("Apply Signature")}
                checked={choice.sig}
                onChange={(event: any) => {
                  setChoice({
                    ...choice,
                    sig: !choice.sig,
                  });
                }}
              />
            )}
            {state.stamp && (
              <CheckBoxDX
                label={t("Apply Stamp")}
                checked={choice.stamp}
                onChange={(event: any) => {
                  setChoice({
                    ...choice,
                    stamp: !choice.stamp,
                  });
                }}
              />
            )}
          </BoxDX>
        ))}
      {page === 2 && (
        <>
          <Typography fontSize={14} textAlign={"center"}>
            {t("Select the area where you need to put the signature")}
          </Typography>
          {isLoading || viewerRef.current === true ? (
            <Loading styles={{ height: "100%", width: "100%" }} />
          ) : (
            state.pdf && (
              <PdfViewer
                url={state.pdf}
                enableAreaSelection={() => true}
                scale={1}
                onAreaSelection={showRect}
                onLoad={(dims) => console.log(dims)}
              >
                {({ document }) => <Viewer document={document} />}
              </PdfViewer>
            )
          )}
        </>
      )}
      {page === 3 && (
        <>
          <Typography fontSize={14} textAlign={"center"}>
            {t("Select the area where you need to put the stamp")}
          </Typography>
          {isLoading || viewerRef.current === true ? (
            <Loading styles={{ height: "100%", width: "100%" }} />
          ) : (
            state.pdf && (
              <PdfViewer
                url={state.pdf}
                enableAreaSelection={() => true}
                scale={1}
                onAreaSelection={showRect}
                onLoad={(dims) => console.log(dims)}
              >
                {({ document }) => <Viewer document={document} />}
              </PdfViewer>
            )
          )}
        </>
      )}
    </AddEditModalDX>
  );
};

export default SignatureModal;
