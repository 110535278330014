import moment, { Moment } from "moment";
import momentHijri from "moment-hijri";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import useCorrespondenceService from "../../../shared/services/correspondenceservice";
import useCorrespondenceTypeService from "../../../shared/services/correspondencetypes";
import useDeliveryService from "../../../shared/services/deliverytypeservice";
import useDomainTypeService from "../../../shared/services/domaintypeservice";
import useImportanceLevelsService from "../../../shared/services/importancelevelsservice";
import usePrivacyLevelsService from "../../../shared/services/privacylevelsservice";
import DatePickerDX from "../../controls/datepickerdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ButtonDX from "../../controls/buttondx";
import ConfirmModal from "../../controls/confirmmodal";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import {
  CorrespondenceStatus,
  handleDateChange,
  handleInputChange,
  sortListAlphabetically,
} from "../../../shared/globals";
import CheckBoxDX from "../../controls/checkboxdx";
import AutoCompleteListDX from "../../controls/autocompletelistdx";
import useEntityService from "../../../shared/services/entityservice";

const EditCorrespondenceModal = (props: any) => {
  const { t, i18n } = useTranslation();

  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const languageIsEn = i18n.language === "en";
  const { setError, setInfo } = useNotificationContext();
  const { updateCorrespondence, getCorrespondenceList } =
    useCorrespondenceService();
  const { getCorrespondenceType } = useCorrespondenceTypeService();
  const { getImportanceLevels } = useImportanceLevelsService();
  const { getPrivacyLevels } = usePrivacyLevelsService();
  const { getDomainTypes } = useDomainTypeService();
  const { getDeliveryTypes } = useDeliveryService();
  const { getEntity } = useEntityService();

  useEffect(() => {
    populateLists();
  }, []);

  const getDate = (date: Moment) => {
    return calendarId === 1 ? moment(date) : momentHijri(date);
  };

  const [correspondenceData, setCorrespondenceData] = useState({
    ...props.correspondenceData,
    correspondenceDate: getDate(props.correspondenceData.correspondenceDate),
    dueDate:
      props.correspondenceData.dueDate !== null
        ? getDate(props.correspondenceData.dueDate)
        : null,
    dateOfReference:
      props.correspondenceData.dateOfReference !== null
        ? getDate(props.correspondenceData.dateOfReference)
        : null,
  });
  const [date, setDate] = useState(correspondenceData.correspondenceDate);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [correspondenceList, setCorrespondenceList] = useState([]);
  const [correspondenceTypeList, setCorrespondenceTypeList] = useState([]);
  const [importanceLevelList, setImportanceLevelList] = useState([]);
  const [privacyLevelList, setPrivacyLevelList] = useState([]);
  const [domainTypeList, setDomainTypeList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [internalEntitesList, setInternalEntitiesList] = useState([]);
  const [externalEntitiesList, setExternalEntitiesList] = useState([]);
  const [entitiesList, setEntitiesList] = useState([]);

  const populateLists = async () => {
    setIsLoading(true);
    const p0 = getCorrespondenceList();
    const p1 = getCorrespondenceType();
    const p2 = getDomainTypes();
    const p3 = getImportanceLevels();
    const p4 = getPrivacyLevels();
    const p5 = getDeliveryTypes();
    const p6 = getEntity();
    Promise.all([
      p0.then((correspondence) => setCorrespondenceList(correspondence)),
      p1.then((correspondenceTypes) =>
        setCorrespondenceTypeList(correspondenceTypes)
      ),
      p2.then((domainTypes) => setDomainTypeList(domainTypes)),
      p3.then((importanceLevels) => setImportanceLevelList(importanceLevels)),
      p4.then((privacyLevels) => setPrivacyLevelList(privacyLevels)),
      p5.then((deliveryTypes) => setDeliveryList(deliveryTypes)),
      p6.then((entities) => {
        setEntitiesList(entities);
        const dataForInternalEntitiesDropDown = entities
          .filter((res: any) => res.domainTypeId === 1)
          .map((res: any) => ({
            text: languageIsEn ? res.enName : res.arName,
            value: res.entityId,
          }));
        setInternalEntitiesList(dataForInternalEntitiesDropDown);
        const dataForExternalEntitiesDropDown = entities
          .filter((res: any) => res.domainTypeId === 2)
          .map((res: any) => ({
            text: languageIsEn ? res.enName : res.arName,
            value: res.entityId,
          }));
        setExternalEntitiesList(dataForExternalEntitiesDropDown);
      }),
    ])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (correspondenceData.subject.length === 0)
      newErrors["subject"] = t("Subject is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = (status: CorrespondenceStatus) => {
    if (validateForm()) {
      setIsSaving(true);
      const data = {
        ...correspondenceData,
        status: status,
      };

      updateCorrespondence(data)
        .then((response) => {
          setInfo(t("Correspondence updated successfully"));
          props.handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  const onClose = () => {
    if (isChanged) setOpen(true);
    else props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      fullWidth
      maxWidth={"xl"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("Edit Correspondence")}
      </DialogTitle>
      <DialogContent>
        {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
        {open && (
          <ConfirmModal
            open={open}
            onYesClick={props.handleClose}
            onNoClick={() => setOpen(false)}
          />
        )}
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Correspondence Type")}
              items={sortListAlphabetically(
                correspondenceTypeList.map((type: any) => {
                  return {
                    value: type.correspondenceTypeId,
                    text: languageIsEn ? type.enName : type.arName,
                  };
                })
              )}
              name="correspondenceTypeId"
              value={correspondenceData.correspondenceTypeId}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Previous Correspondence")}
              items={sortListAlphabetically(
                correspondenceList.map((item: any) => {
                  return {
                    value: item.correspondenceId,
                    text: `${item.correspondenceId} - ${item.subject}`,
                  };
                })
              )}
              name="previousCorrespondenceId"
              value={correspondenceData.previousCorrespondenceId}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <TextFieldDX
              label={t("Subject")}
              name="subject"
              value={correspondenceData.subject}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              error={errors["subject"]}
            />
          </GridDX>
          <GridDX item xs={6}>
            <TextFieldDX
              label={t("Description")}
              name="description"
              value={correspondenceData.description}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <DatePickerDX
              label={t("Correspondence Date")}
              sx={{ flex: 1 }}
              name="correspondenceDate"
              value={correspondenceData.correspondenceDate}
              calendarId={calendarId}
              onChange={(value: any) => {
                setDate(value);
                handleDateChange({
                  value: value,
                  name: "correspondenceDate",
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={true}
            />
          </GridDX>
          <GridDX item xs={6}>
            <DatePickerDX
              label={t("Due Date")}
              sx={{ flex: 1 }}
              name="dueDate"
              minDate={date}
              value={correspondenceData.dueDate}
              calendarId={calendarId}
              onChange={(value: any) => {
                handleDateChange({
                  value: value,
                  name: "dueDate",
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Importance Level")}
              items={sortListAlphabetically(
                importanceLevelList.map((level: any) => {
                  return {
                    value: level.importanceLevelId,
                    text: languageIsEn ? level.enName : level.arName,
                  };
                })
              )}
              name="importanceLevelId"
              value={correspondenceData.importanceLevelId}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Privacy Level")}
              items={sortListAlphabetically(
                privacyLevelList.map((level: any) => {
                  return {
                    value: level.privacyLevelId,
                    text: languageIsEn ? level.enName : level.arName,
                  };
                })
              )}
              name="privacyLevelId"
              value={correspondenceData.privacyLevelId}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Domain Type")}
              items={sortListAlphabetically(
                domainTypeList.map((type: any) => {
                  return {
                    value: type.domainTypeId,
                    text: languageIsEn ? type.enName : type.arName,
                  };
                })
              )}
              name="domainTypeId"
              value={correspondenceData.domainTypeId}
              onChange={(event: any) => {
                setCorrespondenceData(() => {
                  return {
                    ...correspondenceData,
                    entityId: null,
                    entityContactPerson: "",
                    referenceNumber: "",
                    dateOfReference: null,
                  };
                });
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={true}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Delivery Method")}
              items={sortListAlphabetically(
                deliveryList.map((method: any) => {
                  return {
                    value: method.deliveryMethodId,
                    text: languageIsEn ? method.enName : method.arName,
                  };
                })
              )}
              name="deliveryMethodId"
              value={correspondenceData.deliveryMethodId}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          {correspondenceData.domainTypeId && (
            <>
              <GridDX item xs={6}>
                <AutoCompleteListDX
                  label={t("Entity")}
                  items={sortListAlphabetically(
                    entitiesList
                      .filter(
                        (res: any) =>
                          res.domainTypeId === correspondenceData.DomainTypeId
                      )
                      .map((res: any) => ({
                        text: languageIsEn ? res.enName : res.arName,
                        value: res.entityId,
                      }))
                  )}
                  name="entityId"
                  value={correspondenceData.entityId}
                  onChange={(event: any) => {
                    handleInputChange({
                      event: event,
                      setIsChanged: setIsChanged,
                      setData: setCorrespondenceData,
                      data: correspondenceData,
                    });
                  }}
                  disabled={isLoading}
                  // error={}
                />
              </GridDX>
              {correspondenceData.domainTypeId === 2 && (
                <GridDX item xs={6}>
                  <TextFieldDX
                    label={t("Entity Contact Person")}
                    name="entityContactPerson"
                    value={correspondenceData.entityContactPerson}
                    onChange={(event: any) => {
                      handleInputChange({
                        event: event,
                        setIsChanged: setIsChanged,
                        setData: setCorrespondenceData,
                        data: correspondenceData,
                      });
                    }}
                    // error={}
                  />
                </GridDX>
              )}
            </>
          )}
          {correspondenceData.domainTypeId === 2 && (
            <>
              <GridDX item xs={6}>
                <TextFieldDX
                  label={t("Reference Number")}
                  name="referenceNumber"
                  value={correspondenceData.referenceNumber}
                  onChange={(event: any) => {
                    handleInputChange({
                      event: event,
                      setIsChanged: setIsChanged,
                      setData: setCorrespondenceData,
                      data: correspondenceData,
                    });
                  }}
                  // error={}
                />
              </GridDX>
              <GridDX item xs={6}>
                <DatePickerDX
                  label={t("Date of Reference")}
                  sx={{ flex: 1 }}
                  name="dateOfReference"
                  value={moment(correspondenceData.dateOfReference)}
                  calendarId={calendarId}
                  onChange={(value: any) => {
                    handleDateChange({
                      value: value,
                      name: "dateOfReference",
                      setIsChanged: setIsChanged,
                      setData: setCorrespondenceData,
                      data: correspondenceData,
                    });
                  }}
                />
              </GridDX>
            </>
          )}

          <GridDX item xs={12}>
            <CheckBoxDX
              name="applyWatermark"
              label={t("Apply Watermark")}
              checked={correspondenceData.applyWatermark}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
            />
          </GridDX>
          <GridDX item xs={12}>
            <TextFieldDX
              label={t("Notes")}
              fullWidth
              multiline
              name="notes"
              rows={3}
              value={correspondenceData.notes}
              onChange={(event: any, newValue: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              // error={}
            />
          </GridDX>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ flexWrap: "wrap", justifyContent: "space-around" }}>
        <ButtonDX
          variant="outlined"
          onClick={onClose}
          sx={{ mb: { xs: 2, sm: "auto" } }}
        >
          {t("Cancel")}
        </ButtonDX>
        <LoadingButtonDX
          onClick={() => onSave(CorrespondenceStatus.Draft)}
          loading={isSaving}
          loadingPosition="end"
        >
          {t("Save as Draft")}
        </LoadingButtonDX>
        <LoadingButtonDX
          onClick={() => onSave(CorrespondenceStatus.Pending)}
          loading={isSaving}
          loadingPosition="end"
        >
          {t("Save and Send")}
        </LoadingButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default EditCorrespondenceModal;
