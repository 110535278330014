import { useEffect, useState } from "react";
import GridDX from "../../components/layout/griddx";
import useActionTrailService from "../../shared/services/actiontrailservice";

import { useNotificationContext } from "../../context/notificationcontext";
import Loading from "../../components/loading";
import moment from "moment";
import CardDX from "../../components/layout/carddx";
import PageTitle from "../../components/pagetitle";
import FilterRange from "../../components/controls/filterrange";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/authcontext";
import {
  DateTimeFormatter,
  formatUserName,
  FILTER_APIDateFormatter,
} from "../../shared/globals";
import GridSearchFilterTextbox from "../../components/business/gridsearchfiltertextbox";

const DMSLogs = () => {
  const { getAllActionTrails } = useActionTrailService();
  const { setError } = useNotificationContext();
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();

  const languageIsEn = i18n.language === "en";
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const [isLoading, setIsLoading] = useState(true);
  const [dataFromAPI, setDataFromAPI] = useState<any>([]);
  const [logs, setLogs] = useState<any>([]);
  const date = new Date();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    calendarId == 1 ? date.setDate(date.getDate() - 30) : momentHijri(date)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    calendarId == 1 ? new Date() : momentHijri(new Date())
  );

  useEffect(() => {
    getAllActionTrails(
      FILTER_APIDateFormatter(filterFromDate),
      FILTER_APIDateFormatter(filterToDate)
    )
      .then((logs: any) => {
        setLogs(logs);
        setDataFromAPI(logs);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, [i18n.language, filterFromDate, filterToDate]);

  const handleChangeFromDate = (date: any) => {
    setFilterFromDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    }
  };

  const handleChangeToDate = (date: any) => {
    setFilterToDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));

    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    }
  };

  const filterLogs = (value: string) => {
    if (!value.trim()) {
      setLogs(dataFromAPI);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const newRows = dataFromAPI.filter(
      ({ description, performedByUser }: any) =>
        languageIsEn
          ? description.toLowerCase().includes(lowercasedValue) ||
          formatUserName(languageIsEn, performedByUser).toLowerCase().includes(lowercasedValue)
          : description.includes(value) ||
          formatUserName(languageIsEn, performedByUser).includes(value)
    );
    setLogs(newRows);
  };
  return (
    <GridDX
      container
      sx={{ width: "100%", alignItems: "center" }}
      rowSpacing={1}
    >
      {isLoading && <Loading />}
      <GridDX item xs={1.5}>
        <PageTitle />
      </GridDX>
      <GridDX item xs={5.5}>
        <GridSearchFilterTextbox
          setGridFilterCriteria={filterLogs}
        />
      </GridDX>
      <GridDX item xs={5} justifyContent="end">
        <FilterRange
          filterFromDate={filterFromDate}
          filterToDate={filterToDate}
          handleChangeFromDate={handleChangeFromDate}
          handleChangeToDate={handleChangeToDate}
        />
      </GridDX>

      {logs?.map((l: any) => {
        return (
          <CardDX sx={{ width: "100%", flexDirection: "row", my: 1, p: 2 }}>
            <GridDX container fullWidth>
              <GridDX item xs={3}>
                {formatUserName(languageIsEn, l?.performedByUser)}
              </GridDX>
              <GridDX item xs={6}>
                {`${t(l.description.split(":")[0])}:${l.description.split(":")[1]
                  }`}
              </GridDX>
              <GridDX>
                {DateTimeFormatter(
                  l.createdDate,
                  calendarId,
                  gregorianDateFormatId,
                  hijriDateFormatId
                )}
              </GridDX>
            </GridDX>
          </CardDX>
        );
      })}
    </GridDX>
  );
};

export default DMSLogs;
function momentHijri(date: Date): any {
  throw new Error("Function not implemented.");
}
