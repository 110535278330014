import { IconButton, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/authcontext";
import { useNotificationContext } from "../../context/notificationcontext";
import { mergePDFs, addWatermarkToPDF } from "../../shared/pdfoperations";
import ButtonDX from "../controls/buttondx";
import GridDX from "../layout/griddx";
import ArrangeModal from "../editmodals/arrangemodal";
import Loading from "../loading";
import { Close } from "@mui/icons-material";
import { getMIMEType } from "../../shared/globals";
import TextFieldDX from "../controls/textfielddx";
import BoxDX from "../layout/boxdx";

const FileViewer = (props: any) => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { setError } = useNotificationContext();

  // const dummyData: any = {
  //   "Code": 589,
  //   "Name": "Joe",
  //   "Version": 1
  // };

  const type = props.fileType ?? "pdf";
  const contentType = props.fileContentType;
  const applyWatermark = props.applyWatermark ?? false;
  const fileName = props.fileName;
  const [isLoading, setIsLoading] = useState(false);
  const [arrangeOpen, setArrangeOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [originalFile, setOriginalFile] = useState<any>("");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (originalFile !== "") {
      setIsLoading(true);
      processFile()
        .then((fileURL) => setFile(fileURL))
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, [originalFile]);

  const loadData = async () => {
    setIsLoading(true);
    fetch(props.fileURL, {
      headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
    })
      .then((res) => {
        res.arrayBuffer().then((file) => setOriginalFile(file));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onUpload = async (event: any) => {
    const file = event.target.files[0] as File;
    file.arrayBuffer().then((res) =>
      mergePDFs(originalFile, res)
        .then((mergedFile) => {
          const blob = new Blob([mergedFile], { type: "application/pdf" });
          updateFile(blob);
        })
        .catch((err) => setError(err))
    );
  };

  const downloadFile = () => {
    fetch(props.fileURL, {
      headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
    })
      .then(async (res) => {
        const blob = await res.blob();
        const file = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", props.fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err: any) => setError(err));
  };

  const processFile = async () => {
    let fileData = originalFile;
    if (applyWatermark && type === "pdf")
      fileData = await addWatermarkToPDF(originalFile, userData.enFullName);
    const blob = new Blob([fileData], {
      type: contentType ?? getMIMEType(`.${type}`),
    });
    const link = window.URL.createObjectURL(blob);
    return link;
  };

  const updateFile = async (fileBlob: any) => {
    props
      .toUpdate(fileBlob)
      .then((res: any) =>
        fileBlob
          .arrayBuffer()
          .then((updatedFile: any) => setOriginalFile(updatedFile))
      );
  };

  return (
    <GridDX container sx={{ width: "100%", height: "100%" }}>
      {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
      {arrangeOpen && (
        <ArrangeModal
          open={arrangeOpen}
          onClose={() => setArrangeOpen(false)}
          file={originalFile}
          setFile={(fileData: any) => updateFile(fileData)}
        />
      )}
      <GridDX item xs={12} justifyContent="space-between" sx={{ mb: 2 }}>
        <GridDX item xs={4} justifyContent="start">
          <Typography variant="h5"> {fileName} </Typography>
        </GridDX>
        <GridDX item xs={8} justifyContent="end">
          {props.onBackClick && (
            <ButtonDX
              variant="outlined"
              sx={{ mx: 1 }}
              onClick={props.onBackClick}
            >
              {t("Back")}
            </ButtonDX>
          )}
          {type === "pdf" && props.toUpdate && (
            <>
              <ButtonDX sx={{ mx: 1 }} onClick={() => setArrangeOpen(true)}>
                {t("Arrange PDF")}
              </ButtonDX>
              <ButtonDX variant="contained" component="label" sx={{ mx: 1 }}>
                {t("Merge PDF")}
                <input
                  type="file"
                  hidden
                  onChange={onUpload}
                  accept="application/pdf"
                />
              </ButtonDX>
            </>
          )}
          <ButtonDX sx={{ mx: 1 }} onClick={downloadFile}>
            {t("Download")}
          </ButtonDX>
          {props.onCloseClick && (
            <BoxDX>
              <IconButton onClick={props.onCloseClick}>
                <Close />
              </IconButton>
            </BoxDX>
          )}
        </GridDX>
      </GridDX>
      {props.sideInfo && (
        <GridDX item xs={2} px={1} flexDirection="column">
          {Object.entries(props.sideInfo).map(([key, value]) => (
            <TextFieldDX
              readOnly
              label={t(key)}
              value={value}
              sx={{ my: 1 }}
              placeholder={t("No Information")}
            />
          ))}
          {props.forMoreInfo && (
            <ButtonDX sx={{ my: 1 }} onClick={props.forMoreInfo}>
              {t("More Information")}
            </ButtonDX>
          )}
        </GridDX>
      )}
      <GridDX item xs={props.sideInfo ? 10 : 12} sx={{ height: "100%" }}>
        {file && (
          <iframe src={file} width="100%" height="100%" title="File Viewer" />
        )}
      </GridDX>
    </GridDX>
  );
};

export default FileViewer;
