import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

import GridDX from "../../../components/layout/griddx";
import WhiteBox from "../../../components/layout/whitebox";
import ChartBox from "../../../components/layout/chartbox";
import CorrespondenceListTabs from "./correspondencelisttabs";

import useDashboardService from "../../../shared/services/dashboardservice";

import { useNotificationContext } from "../../../context/notificationcontext";
import FilterRange from "../../../components/controls/filterrange";
import ActionDropDownMenuDx from "../../../components/controls/dropdownmenudx";
import { useAuthContext } from "../../../context/authcontext";
import momentHijri from "moment-hijri";
import { FILTER_APIDateFormatter } from "../../../shared/globals";
import useCorrespondenceTypeService from "../../../shared/services/correspondencetypes";
import useDomainTypeService from "../../../shared/services/domaintypeservice";
import PageTitle from "../../../components/pagetitle";

const Correspondence = () => {
  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError } = useNotificationContext();
  const {
    getSentAndNotReceivedCount,
    getRequiresMyActionCount,
    getOverDueBreakupCount,
    getOpenBreakupCount,
  } = useDashboardService();
  const [isLoading, setIsLoading] = useState(true);
  const [sentAndNotReceivedCount, setSentAndNotReceivedCount] = useState(0);
  const [requiresMyActionCount, setRequiresMyActionCount] = useState(0);
  const [overdueBreakup, setOverdueBreakup] = useState<any>([
    { id: 0, value: 0, label: t("By Me") },
    { id: 1, value: 0, label: t("By Others") },
  ]);
  const [openBreakup, setOpenBreakup] = useState<any>([
    { id: 0, value: 0, label: t("By Me") },
    { id: 1, value: 10, label: t("By Others") },
  ]);

  const { getCorrespondenceType } = useCorrespondenceTypeService();
  const { getDomainTypes } = useDomainTypeService();
  const [correspondenceTypeId, setCorrespondenceTypeId] = useState<any>(null);
  const [domainTypeId, setDomainTypeId] = useState<any>(null);
  const date = new Date();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    calendarId == 1 ? date.setDate(date.getDate() - 30) : momentHijri(date)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    calendarId == 1 ? new Date() : momentHijri(new Date())
  );
  const handleChangeFromDate = (date: any) => {
    setFilterFromDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    }
  };
  const handleChangeToDate = (date: any) => {
    setFilterToDate(
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date))
    );
    const filterDate =
      calendarId == 1 ? new Date(date) : momentHijri(new Date(date));

    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    }
  };

  const [domainTypes, setDomainTypes] = useState<any>([]);
  const [correspondenceTypes, setCorrespondenceTypes] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    const p1 = getCorrespondenceType();
    const p2 = getDomainTypes();
    Promise.all([
      p1.then((types) => setCorrespondenceTypes(types)),
      p2.then((domains) => setDomainTypes(domains))])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const getCorrespondenceCardsData = () => {
    setIsLoading(true);
    const p1 = getSentAndNotReceivedCount(FILTER_APIDateFormatter(filterFromDate), FILTER_APIDateFormatter(filterToDate), correspondenceTypeId, domainTypeId);
    const p2 = getRequiresMyActionCount(FILTER_APIDateFormatter(filterFromDate), FILTER_APIDateFormatter(filterToDate), correspondenceTypeId, domainTypeId);
    const p3 = getOverDueBreakupCount(FILTER_APIDateFormatter(filterFromDate), FILTER_APIDateFormatter(filterToDate), correspondenceTypeId, domainTypeId);
    const p4 = getOpenBreakupCount(FILTER_APIDateFormatter(filterFromDate), FILTER_APIDateFormatter(filterToDate), correspondenceTypeId, domainTypeId);

    Promise.all([
      p1.then((data: number) => setSentAndNotReceivedCount(data)),
      p2.then((data: number) => setRequiresMyActionCount(data)),
      p3.then((data: any) => {
        setOverdueBreakup(() => {
          return [
            { id: 0, value: data.overdueByMe, label: t("By Me") },
            { id: 1, value: data.overdueByOthers, label: t("By Others") },
          ];
        });
      }),
      p4.then((data: any) =>
        setOpenBreakup(() => {
          return [
            { id: 0, value: data.openByMe, label: t("By Me") },
            { id: 1, value: data.openByOthers, label: t("By Others") },
          ];
        }
        )),
    ])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getCorrespondenceCardsData();
  }, [filterFromDate, filterToDate, correspondenceTypeId, domainTypeId, i18n.language]);
  return (
    <GridDX
      container
      sx={{ width: "100%", alignItems: "center" }}
      rowSpacing={2}
      columnSpacing={1}
    >
      <GridDX item xs={2}>
        <PageTitle />
      </GridDX>
      <GridDX item xs={10} justifyContent="end">
        <GridDX>
          <ActionDropDownMenuDx
            label={
              domainTypeId
                ? languageIsEn
                  ? domainTypes.find((x: any) => x.domainTypeId === domainTypeId)?.enName
                  : domainTypes.find((x: any) => x.domainTypeId === domainTypeId)?.arName
                : t("Domain")
            }
            actions={domainTypes
              .map((domain: any) => ({
                label: languageIsEn ? domain.enName : domain.arName,
                onClick: () => setDomainTypeId(domain.domainTypeId),
              }))
              .concat({
                label: t("Reset"),
                onClick: () => setDomainTypeId(null),
              })}
          />
        </GridDX>
        <GridDX>
          <ActionDropDownMenuDx
            label={
              correspondenceTypeId
                ? languageIsEn
                  ? correspondenceTypes.find((x: any) => x.correspondenceTypeId === correspondenceTypeId)?.enName
                  : correspondenceTypes.find((x: any) => x.correspondenceTypeId === correspondenceTypeId)?.arName
                : t("Correspondence")
            }
            actions={correspondenceTypes
              .map((type: any) => ({
                label: languageIsEn ? type.enName : type.arName,
                onClick: () => setCorrespondenceTypeId(type.correspondenceTypeId),
              }))
              .concat({
                label: t("Reset"),
                onClick: () => setCorrespondenceTypeId(null),
              })}
          />
        </GridDX>
        <FilterRange
          filterFromDate={filterFromDate}
          filterToDate={filterToDate}
          handleChangeFromDate={handleChangeFromDate}
          handleChangeToDate={handleChangeToDate}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <WhiteBox
          title={t("Sent & Not Received")}
          loading={isLoading}
          count={sentAndNotReceivedCount}
          icon={<ForwardToInboxOutlinedIcon sx={{ fontSize: 100 }} />}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <WhiteBox
          title={t("Requires my Action")}
          loading={isLoading}
          count={requiresMyActionCount}
          icon={<MarkEmailReadOutlinedIcon sx={{ fontSize: 100 }} />}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <ChartBox
          title={t("Overdue")}
          loading={isLoading}
          data={overdueBreakup}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <ChartBox title={t("Open")} loading={isLoading} data={openBreakup} />
      </GridDX>
      <GridDX item xs={12}>
        <CorrespondenceListTabs
          filterFromDate={FILTER_APIDateFormatter(filterFromDate)}
          filterToDate={FILTER_APIDateFormatter(filterToDate)}
          correspondenceTypeId={correspondenceTypeId}
          domainTypeId={domainTypeId}
          getCorrespondenceCardsData={getCorrespondenceCardsData}
        />
      </GridDX>
    </GridDX>
  );
};

export default Correspondence;
