import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@mui/material";
import ButtonDX from "./buttondx";
import GridDX from "../layout/griddx";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TextFieldDX from "./textfielddx";

const CorrespondenceConfirmModal = (props: any) => {

  const { open, onCancelClick, onConfirmClick, label, name } = props
  const { t, i18n } = useTranslation();
  const [rejectionReason, setRejectionReason] = useState("");
  const [errors, setErrors] = useState<any>({});
  const handleConfirmClick = () => {
    if (ValidateReason()) {
      onConfirmClick(rejectionReason);
      setRejectionReason("");
    }
  };

  const ValidateReason = () => {
    let errors: any = {};
    if (label == "Reject" && !rejectionReason) {
      errors.rejectionReason = t("Rejection Reason is required");
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth={"xs"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{t("Are You Sure?")}</DialogTitle>
      <DialogContent>
        <GridDX container justifyContent="center" direction="column">
          <Typography sx={{ textAlign: "center" }}  >
            {t(`You want to ${label} this ${name || "correspondence"}?`)}
          </Typography>

          {label === "Reject" && (
            <GridDX sm={12} sx={{ mt: 1 }}>
              <TextFieldDX
                label={t("Reason")}
                name="notes"
                type="text"
                multiline
                rows={2}
                fullWidth
                value={rejectionReason}
                onChange={(e: any) => {
                  setRejectionReason(e.target.value);
                }}
                error={errors["rejectionReason"]}
              />
            </GridDX>
          )}
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <ButtonDX
          variant="outlined"
          onClick={() => onCancelClick()}
        >
          {t("Cancel")}
        </ButtonDX>
        <ButtonDX
          onClick={handleConfirmClick}
        >
          {t("Yes")}
        </ButtonDX>
      </DialogActions>
    </Dialog >
  );
}

export default CorrespondenceConfirmModal;