import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import { useNotificationContext } from "../../../../context/notificationcontext";
import { useAuthContext } from "../../../../context/authcontext";

import FollowersModal from "../../../../components/editmodals/correspondence/correspondencefollowersmodal";
import ListPageDX from "../../../../components/business/listpagedx";

import useFollowerService from "../../../../shared/services/followers";
import {
  CorrespondenceStatus,
  DateTimeFormatter,
  DateSorter,
} from "../../../../shared/globals";

const CorrespondenceFollowers = (props: any) => {
  const { status } = props;
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;

  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const { getFollowers, deleteFollower } = useFollowerService();
  const languageIsEn = i18n.language === "en";

  const [rows, setRows] = useState<any>([]);
  const [followers, setFollowers] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getFollowersList();
  }, [i18n.language]);

  const getFollowersList = async () => {
    setIsLoading(true);
    getFollowers(props.correspondenceId)
      .then((response: any) => {
        // const list = response.map((item: any) => item.user);
        setFollowers(response);
        setRows(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (userId: any) => {
    setIsLoading(true);
    deleteFollower(props.correspondenceId, userId)
      .then(() => {
        setInfo(t("Data deleted successfully"));
        getFollowersList();
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    setOpen(true);
  };

  const toClose = (refreshPage: boolean = false) => {
    setOpen(false);

    if (refreshPage) getFollowersList();
  };

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: `${t("User")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return languageIsEn
          ? params.row.user.enFullName
          : params.row.user.arFullName;
      },
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,
      valueGetter: (params: any) => {
        return languageIsEn
          ? params.row.createdByUser.enFullName
          : params.row.createdByUser.arFullName;
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Date Added")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(followers);
      return;
    }

    const lowercasedValue = value.toLowerCase();

    const newRows = followers.filter(({ user, createdByUser }: any) =>
      languageIsEn
        ? user.enFullName.toLowerCase().includes(lowercasedValue) ||
          createdByUser.enFullName.toLowerCase().includes(lowercasedValue)
        : user.arFullName.includes(value) ||
          createdByUser.arFullName.includes(value)
    );
    setRows(newRows);
  };

  return (
    <>
      {open && (
        <FollowersModal
          open={open}
          handleClose={toClose}
          followers={rows.map((item: any) => item.id)}
          correspondenceId={props.correspondenceId}
        />
      )}
      <ListPageDX
        listTitle={t("Followers")}
        name={t("Follower")}
        createLabel={t("Add Followers")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.userId}
        isLoading={isLoading}
        toCreate={toCreate}
        onDelete={
          status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft
            ? onDelete
            : null
        }
        exportToPDF={false}
        exportToCSV={false}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default CorrespondenceFollowers;
