import useSecureAPI from "../hooks/usesecureapi";

const useDocumentActionTrailService = () => {
  const secureAPI = useSecureAPI();

  const getActionTrailByDocumentID = async (id: number) => {
    const response = await secureAPI.get(`/dms/DocumentActionTrail/forDocument/${id}`);
    return response.data;
  }; 

  const getAllDocumentActionTrails = async (startDate: string, endDate: string) => {
    const response = await secureAPI.get(`dms/DocumentActionTrail/${startDate}/${endDate}`);
    return response.data;
  };

  return {
    getActionTrailByDocumentID,
    getAllDocumentActionTrails
  };
};

export default useDocumentActionTrailService;
