import { useTranslation } from "react-i18next";
import { FixedSizeList } from "react-window";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";

const VirtualizedListDX = (props: any) => {
  const { itemCount, renderRow } = props;
  const { i18n } = useTranslation();

  return (
    <AutoSizer>
      {({ height, width }: Size) => (
        <FixedSizeList
          height={height}
          width={width}
          itemSize={65}
          itemCount={itemCount}
          overscanCount={5}
          direction={i18n.language === "en" ? "ltr" : "rtl"}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default VirtualizedListDX;
