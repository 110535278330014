import useSecureAPI from "../hooks/usesecureapi";

const useDMSNotificationService = () => {
    const secureAPI = useSecureAPI();
    
    const getDMSNotificationsByUser = async (id: any) => {
        const response = await secureAPI.get(`dms/DMSUserNotification/foruser/${id}`);
        return response.data;
    }

    const getDMSUnreadNotificationsCount = async (id: any) => {
      const response = await secureAPI.get(`dms/DMSUserNotification/unreadcount/${id}`);
      return response.data;
    }

    const markAsReadDMS = async (id: any) => {
        const response = await secureAPI.put(`dms/DMSUserNotification/MarkRead/${id}`);
        return response.data;
    }

    const markUnReadDMS = async (id: any) => {
        const response = await secureAPI.put(`dms/DMSUserNotification/MarkUnRead/${id}`);
        return response.data;
    }

    const markAsArchivedDMS = async (id: any) => {
        const response = await secureAPI.delete(`dms/DMSUserNotification/${id}`);
        return response.data;
    }

    const markAllUnReadDMS = async (userId: any) => {
        const response = await secureAPI.put(`dms/DMSUserNotification/MarkAllUnRead/foruser/${userId}`);
        return response.data;
    }

    return {
        getDMSNotificationsByUser,
        getDMSUnreadNotificationsCount,
        markAsReadDMS,
        markUnReadDMS,
        markAllUnReadDMS,
        markAsArchivedDMS,
    };
};

export default useDMSNotificationService;